import * as _delegateIt4 from "delegate-it";

var _delegateIt3 = "default" in _delegateIt4 ? _delegateIt4.default : _delegateIt4;

import _Cache3 from "./modules/Cache";
import _loadPage3 from "./modules/loadPage";
import _renderPage3 from "./modules/renderPage";
import _triggerEvent3 from "./modules/triggerEvent";
import _on3 from "./modules/on";
import _off3 from "./modules/off";
import _updateTransition3 from "./modules/updateTransition";
import _getAnchorElement3 from "./modules/getAnchorElement";
import _getAnimationPromises3 from "./modules/getAnimationPromises";
import _getPageData3 from "./modules/getPageData";
import _plugins2 from "./modules/plugins";
import _utils2 from "./utils";
import _helpers2 from "./helpers";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}(); // modules


var _delegateIt = _delegateIt3;

var _delegateIt2 = _interopRequireDefault(_delegateIt);

var _Cache = _Cache3;

var _Cache2 = _interopRequireDefault(_Cache);

var _loadPage = _loadPage3;

var _loadPage2 = _interopRequireDefault(_loadPage);

var _renderPage = _renderPage3;

var _renderPage2 = _interopRequireDefault(_renderPage);

var _triggerEvent = _triggerEvent3;

var _triggerEvent2 = _interopRequireDefault(_triggerEvent);

var _on = _on3;

var _on2 = _interopRequireDefault(_on);

var _off = _off3;

var _off2 = _interopRequireDefault(_off);

var _updateTransition = _updateTransition3;

var _updateTransition2 = _interopRequireDefault(_updateTransition);

var _getAnchorElement = _getAnchorElement3;

var _getAnchorElement2 = _interopRequireDefault(_getAnchorElement);

var _getAnimationPromises = _getAnimationPromises3;

var _getAnimationPromises2 = _interopRequireDefault(_getAnimationPromises);

var _getPageData = _getPageData3;

var _getPageData2 = _interopRequireDefault(_getPageData);

var _plugins = _plugins2;
var _utils = _utils2;
var _helpers = _helpers2;

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

var Swup = function () {
  function Swup(setOptions) {
    _classCallCheck(this, Swup); // default options


    var defaults = {
      animateHistoryBrowsing: false,
      animationSelector: "[class*=\"transition-\"]",
      linkSelector: "a[href^=\"" + window.location.origin + "\"]:not([data-no-swup]), a[href^=\"/\"]:not([data-no-swup]), a[href^=\"#\"]:not([data-no-swup])",
      cache: true,
      containers: ["#swup"],
      requestHeaders: {
        "X-Requested-With": "swup",
        Accept: "text/html, application/xhtml+xml"
      },
      plugins: [],
      skipPopStateHandling: function skipPopStateHandling(event) {
        return !(event.state && event.state.source === "swup");
      }
    }; // merge options

    var options = _extends({}, defaults, setOptions); // handler arrays


    this._handlers = {
      animationInDone: [],
      animationInStart: [],
      animationOutDone: [],
      animationOutStart: [],
      animationSkipped: [],
      clickLink: [],
      contentReplaced: [],
      disabled: [],
      enabled: [],
      openPageInNewTab: [],
      pageLoaded: [],
      pageRetrievedFromCache: [],
      pageView: [],
      popState: [],
      samePage: [],
      samePageWithHash: [],
      serverError: [],
      transitionStart: [],
      transitionEnd: [],
      willReplaceContent: []
    }; // variable for anchor to scroll to after render

    this.scrollToElement = null; // variable for promise used for preload, so no new loading of the same page starts while page is loading

    this.preloadPromise = null; // variable for save options

    this.options = options; // variable for plugins array

    this.plugins = []; // variable for current transition object

    this.transition = {}; // variable for keeping event listeners from "delegate"

    this.delegatedListeners = {}; // so we are able to remove the listener

    this.boundPopStateHandler = this.popStateHandler.bind(this); // make modules accessible in instance

    this.cache = new _Cache2.default();
    this.cache.swup = this;
    this.loadPage = _loadPage2.default;
    this.renderPage = _renderPage2.default;
    this.triggerEvent = _triggerEvent2.default;
    this.on = _on2.default;
    this.off = _off2.default;
    this.updateTransition = _updateTransition2.default;
    this.getAnimationPromises = _getAnimationPromises2.default;
    this.getPageData = _getPageData2.default;
    this.getAnchorElement = _getAnchorElement2.default;

    this.log = function () {}; // here so it can be used by plugins


    this.use = _plugins.use;
    this.unuse = _plugins.unuse;
    this.findPlugin = _plugins.findPlugin;
    this.getCurrentUrl = _helpers.getCurrentUrl;
    this.cleanupAnimationClasses = _helpers.cleanupAnimationClasses; // enable swup

    this.enable();
  }

  _createClass(Swup, [{
    key: "enable",
    value: function enable() {
      var _this = this; // check for Promise support


      if (typeof Promise === "undefined") {
        console.warn("Promise is not supported");
        return;
      } // add event listeners


      this.delegatedListeners.click = (0, _delegateIt2.default)(document, this.options.linkSelector, "click", this.linkClickHandler.bind(this));
      window.addEventListener("popstate", this.boundPopStateHandler); // initial save to cache

      if (this.options.cache) {} // disabled to avoid caching modified dom state
      // https://github.com/swup/swup/issues/475
      // logic moved to preload plugin
      // mark swup blocks in html


      (0, _helpers.markSwupElements)(document.documentElement, this.options.containers); // mount plugins

      this.options.plugins.forEach(function (plugin) {
        _this.use(plugin);
      }); // modify initial history record

      window.history.replaceState(Object.assign({}, window.history.state, {
        url: window.location.href,
        random: Math.random(),
        source: "swup"
      }), document.title, window.location.href); // trigger enabled event

      this.triggerEvent("enabled"); // add swup-enabled class to html tag

      document.documentElement.classList.add("swup-enabled"); // trigger page view event

      this.triggerEvent("pageView");
    }
  }, {
    key: "destroy",
    value: function destroy() {
      var _this2 = this; // remove delegated listeners


      this.delegatedListeners.click.destroy(); // remove popstate listener

      window.removeEventListener("popstate", this.boundPopStateHandler); // empty cache

      this.cache.empty(); // unmount plugins

      this.options.plugins.forEach(function (plugin) {
        _this2.unuse(plugin);
      }); // remove swup data atributes from blocks

      (0, _utils.queryAll)("[data-swup]").forEach(function (element) {
        element.removeAttribute("data-swup");
      }); // remove handlers

      this.off(); // trigger disable event

      this.triggerEvent("disabled"); // remove swup-enabled class from html tag

      document.documentElement.classList.remove("swup-enabled");
    }
  }, {
    key: "linkClickHandler",
    value: function linkClickHandler(event) {
      // no control key pressed
      if (!event.metaKey && !event.ctrlKey && !event.shiftKey && !event.altKey) {
        // index of pressed button needs to be checked because Firefox triggers click on all mouse buttons
        if (event.button === 0) {
          this.triggerEvent("clickLink", event);
          event.preventDefault();
          var link = new _helpers.Link(event.delegateTarget);

          if (link.getAddress() == (0, _helpers.getCurrentUrl)() || link.getAddress() == "") {
            // link to the same URL
            if (link.getHash() != "") {
              // link to the same URL with hash
              this.triggerEvent("samePageWithHash", event);
              var element = (0, _getAnchorElement2.default)(link.getHash());

              if (element != null) {
                history.replaceState({
                  url: link.getAddress() + link.getHash(),
                  random: Math.random(),
                  source: "swup"
                }, document.title, link.getAddress() + link.getHash());
              } else {
                // referenced element not found
                console.warn("Element for offset not found (" + link.getHash() + ")");
              }
            } else {
              // link to the same URL without hash
              this.triggerEvent("samePage", event);
            }
          } else {
            // link to different url
            if (link.getHash() != "") {
              this.scrollToElement = link.getHash();
            } // get custom transition from data


            var customTransition = event.delegateTarget.getAttribute("data-swup-transition"); // load page

            this.loadPage({
              url: link.getAddress(),
              customTransition: customTransition
            }, false);
          }
        }
      } else {
        // open in new tab (do nothing)
        this.triggerEvent("openPageInNewTab", event);
      }
    }
  }, {
    key: "popStateHandler",
    value: function popStateHandler(event) {
      if (this.options.skipPopStateHandling(event)) return;
      var link = new _helpers.Link(event.state ? event.state.url : window.location.pathname);

      if (link.getHash() !== "") {
        this.scrollToElement = link.getHash();
      } else {
        event.preventDefault();
      }

      this.triggerEvent("popState", event);

      if (!this.options.animateHistoryBrowsing) {
        document.documentElement.classList.remove("is-animating");
        (0, _helpers.cleanupAnimationClasses)();
      }

      this.loadPage({
        url: link.getAddress()
      }, event);
    }
  }]);

  return Swup;
}();

exports.default = Swup;
export default exports;
export const __esModule = exports.__esModule;