var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});

var off = function off(event, handler) {
  var _this = this;

  if (event != null) {
    if (handler != null) {
      if (this._handlers[event] && this._handlers[event].filter(function (savedHandler) {
        return savedHandler === handler;
      }).length) {
        var toRemove = this._handlers[event].filter(function (savedHandler) {
          return savedHandler === handler;
        })[0];

        var index = this._handlers[event].indexOf(toRemove);

        if (index > -1) {
          this._handlers[event].splice(index, 1);
        }
      } else {
        console.warn("Handler for event '" + event + "' no found.");
      }
    } else {
      this._handlers[event] = [];
    }
  } else {
    Object.keys(this._handlers).forEach(function (keys) {
      _this._handlers[keys] = [];
    });
  }
};

exports.default = off;
export default exports;