import _utils2 from "../utils";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _utils = _utils2;

var getAnchorElement = function getAnchorElement(hash) {
  if (!hash) {
    return null;
  }

  if (hash.charAt(0) === "#") {
    hash = hash.substring(1);
  }

  hash = decodeURIComponent(hash);
  hash = (0, _utils.escapeCssIdentifier)(hash); // https://html.spec.whatwg.org/#find-a-potential-indicated-element

  return (0, _utils.query)("#" + hash) || (0, _utils.query)("a[name='" + hash + "']");
};

exports.default = getAnchorElement;
export default exports;