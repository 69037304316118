var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});

var updateTransition = function updateTransition(from, to, custom) {
  // transition routes
  this.transition = {
    from: from,
    to: to,
    custom: custom
  };
};

exports.default = updateTransition;
export default exports;