import _helpers2 from "../helpers";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray = function () {
  function sliceIterator(arr, i) {
    var _arr = [];
    var _n = true;
    var _d = false;
    var _e = undefined;

    try {
      for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
        _arr.push(_s.value);

        if (i && _arr.length === i) break;
      }
    } catch (err) {
      _d = true;
      _e = err;
    } finally {
      try {
        if (!_n && _i["return"]) _i["return"]();
      } finally {
        if (_d) throw _e;
      }
    }

    return _arr;
  }

  return function (arr, i) {
    if (Array.isArray(arr)) {
      return arr;
    } else if (Symbol.iterator in Object(arr)) {
      return sliceIterator(arr, i);
    } else {
      throw new TypeError("Invalid attempt to destructure non-iterable instance");
    }
  };
}();

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

var _helpers = _helpers2;

var loadPage = function loadPage(data, popstate) {
  var _this = this; // create array for storing animation promises


  var animationPromises = [],
      xhrPromise = void 0;

  var animateOut = function animateOut() {
    _this.triggerEvent("animationOutStart"); // handle classes


    document.documentElement.classList.add("is-changing");
    document.documentElement.classList.add("is-leaving");
    document.documentElement.classList.add("is-animating");

    if (popstate) {
      document.documentElement.classList.add("is-popstate");
    }

    document.documentElement.classList.add("to-" + (0, _helpers.classify)(data.url)); // animation promise stuff

    animationPromises = _this.getAnimationPromises("out");
    Promise.all(animationPromises).then(function () {
      _this.triggerEvent("animationOutDone");
    }); // create history record if this is not a popstate call

    if (!popstate) {
      // create pop element with or without anchor
      var state = void 0;

      if (_this.scrollToElement != null) {
        state = data.url + _this.scrollToElement;
      } else {
        state = data.url;
      }

      (0, _helpers.createHistoryRecord)(state);
    }
  };

  this.triggerEvent("transitionStart", popstate); // set transition object

  if (data.customTransition != null) {
    this.updateTransition(window.location.pathname, data.url, data.customTransition);
    document.documentElement.classList.add("to-" + (0, _helpers.classify)(data.customTransition));
  } else {
    this.updateTransition(window.location.pathname, data.url);
  } // start/skip animation


  if (!popstate || this.options.animateHistoryBrowsing) {
    animateOut();
  } else {
    this.triggerEvent("animationSkipped");
  } // start/skip loading of page


  if (this.cache.exists(data.url)) {
    xhrPromise = new Promise(function (resolve) {
      resolve(_this.cache.getPage(data.url));
    });
    this.triggerEvent("pageRetrievedFromCache");
  } else {
    if (!this.preloadPromise || this.preloadPromise.route != data.url) {
      xhrPromise = new Promise(function (resolve, reject) {
        (0, _helpers.fetch)(_extends({}, data, {
          headers: _this.options.requestHeaders
        }), function (response) {
          if (response.status === 500) {
            _this.triggerEvent("serverError");

            reject(data.url);
            return;
          } else {
            // get json data
            var page = _this.getPageData(response);

            if (page != null && page.blocks.length > 0) {
              page.url = data.url;
            } else {
              reject(data.url);
              return;
            } // render page


            _this.cache.cacheUrl(page);

            _this.triggerEvent("pageLoaded");

            resolve(page);
          }
        });
      });
    } else {
      xhrPromise = this.preloadPromise;
    }
  } // when everything is ready, handle the outcome


  Promise.all([xhrPromise].concat(animationPromises)).then(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 1),
        pageData = _ref2[0]; // render page


    _this.renderPage(pageData, popstate);

    _this.preloadPromise = null;
  }).catch(function (errorUrl) {
    // rewrite the skipPopStateHandling function to redirect manually when the history.go is processed
    _this.options.skipPopStateHandling = function () {
      window.location = errorUrl;
      return true;
    }; // go back to the actual page were still at


    window.history.go(-1);
  });
};

exports.default = loadPage;
export default exports;