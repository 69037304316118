var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});

var use = exports.use = function use(plugin) {
  if (!plugin.isSwupPlugin) {
    console.warn("Not swup plugin instance " + plugin + ".");
    return;
  }

  this.plugins.push(plugin);
  plugin.swup = this;

  if (typeof plugin._beforeMount === "function") {
    plugin._beforeMount();
  }

  plugin.mount();
  return this.plugins;
};

var unuse = exports.unuse = function unuse(plugin) {
  var pluginReference = void 0;

  if (typeof plugin === "string") {
    pluginReference = this.plugins.find(function (p) {
      return plugin === p.name;
    });
  } else {
    pluginReference = plugin;
  }

  if (!pluginReference) {
    console.warn("No such plugin.");
    return;
  }

  pluginReference.unmount();

  if (typeof pluginReference._afterUnmount === "function") {
    pluginReference._afterUnmount();
  }

  var index = this.plugins.indexOf(pluginReference);
  this.plugins.splice(index, 1);
  return this.plugins;
};

var findPlugin = exports.findPlugin = function findPlugin(pluginName) {
  return this.plugins.find(function (p) {
    return pluginName === p.name;
  });
};

export default exports;