import _helpers2 from "../helpers";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Cache = undefined;

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _helpers = _helpers2;

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

var Cache = exports.Cache = function () {
  function Cache() {
    _classCallCheck(this, Cache);

    this.pages = {};
    this.last = null;
  }

  _createClass(Cache, [{
    key: "cacheUrl",
    value: function cacheUrl(page) {
      page.url = (0, _helpers.normalizeUrl)(page.url);

      if (page.url in this.pages === false) {
        this.pages[page.url] = page;
      }

      this.last = this.pages[page.url];
      this.swup.log("Cache (" + Object.keys(this.pages).length + ")", this.pages);
    }
  }, {
    key: "getPage",
    value: function getPage(url) {
      url = (0, _helpers.normalizeUrl)(url);
      return this.pages[url];
    }
  }, {
    key: "getCurrentPage",
    value: function getCurrentPage() {
      return this.getPage((0, _helpers.getCurrentUrl)());
    }
  }, {
    key: "exists",
    value: function exists(url) {
      url = (0, _helpers.normalizeUrl)(url);
      return url in this.pages;
    }
  }, {
    key: "empty",
    value: function empty() {
      this.pages = {};
      this.last = null;
      this.swup.log("Cache cleared");
    }
  }, {
    key: "remove",
    value: function remove(url) {
      delete this.pages[url];
    }
  }]);

  return Cache;
}();

exports.default = Cache;
export default exports;