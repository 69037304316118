import _helpers2 from "../helpers";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _helpers = _helpers2;

var getPageData = function getPageData(request) {
  // this method can be replaced in case other content than html is expected to be received from server
  // this function should always return {title, pageClass, originalContent, blocks, responseURL}
  // in case page has invalid structure - return null
  var html = request.responseText;
  var pageObject = (0, _helpers.getDataFromHtml)(html, this.options.containers);

  if (pageObject) {
    pageObject.responseURL = request.responseURL ? request.responseURL : window.location.href;
  } else {
    console.warn("[swup] Received page is invalid.");
    return null;
  }

  return pageObject;
};

exports.default = getPageData;
export default exports;